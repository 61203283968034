
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { useRouter, useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";

import EditLegalitasDokumenPKS from "@/components/1crm/legal/legalitas/superadmin/edit-dokumen-pks.vue";
import EditLegalitasDokumenHardcopy from "@/components/1crm/legal/legalitas/superadmin/edit-dokumen-hardcopy.vue";
import EditLegalitasRiwayatDokumen from "@/components/1crm/legal/legalitas/superadmin/edit-history-dokumen.vue";

interface items {
  editPKS: Boolean;
  editHardcopy: Boolean;
  riwayat: Boolean;
  showEditHardcopy: Boolean;
  statuslegal : string;
}

export default defineComponent({
  name: "Cabang",
  components: {
    ErrorMessage,
    Field,
    Form,
    EditLegalitasDokumenPKS,
    EditLegalitasDokumenHardcopy,
    EditLegalitasRiwayatDokumen,
  },

  setup() {    
    const router = useRouter();
    const route = useRoute();
    
    let items = reactive<items>({
      editPKS: true,
      editHardcopy: false,
      riwayat: false,
      showEditHardcopy: false,
      statuslegal: '',
    });

    onMounted(() => {
      store.dispatch(Actions.VERIFY_AUTH);
      getIdDetailLegalitas();
      setCurrentPageBreadcrumbs("Edit Legalitas", ["Data"]);
      var typelegal = localStorage.getItem("typelegal");
      let promise = new Promise((resolve, reject) => {setTimeout(() => checktypeandstatus(typelegal,items.statuslegal), 1000)});
      
    });

    function checktypeandstatus(typelegal, statuslegal) {
      if(typelegal){
        if(typelegal == 'PKS' && statuslegal == 'Approve'){
          items.showEditHardcopy = true;
        }else{
          items.showEditHardcopy = false;
        }
        // localStorage.removeItem("typelegal");
      }
    }

    const getIdDetailLegalitas = () => {
      ApiService.getWithoutSlug(
        "crmv2/main_legal/legal/legal/get_document_legal/" +
          route.params.idSekolah +
          "/" +
          route.params.idDokumen
      )
        .then((res) => {
          items.statuslegal = res.data.documentLegality.status;
        })
        .catch((e) => {
          // toast.error(e.response.data.detail);
        });
    };
    
    
    const DokumenPKS = () => {
      items.editPKS = true;
      items.editHardcopy = false;
      items.riwayat = false;
    };
    const DokumenHardcopy = () => {
      items.editPKS = false;
      items.editHardcopy = true;
      items.riwayat = false;
    };
    const RiwayatDokumen = () => {
      items.editPKS = false;
      items.editHardcopy = false;
      items.riwayat = true;
    };

    return {
      DokumenPKS,
      DokumenHardcopy,
      RiwayatDokumen,
      items,
      getIdDetailLegalitas,
    };
  },
});
