
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import moment from "moment";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import Loading from "vue-loading-overlay";

interface editDetail {
  number: string;
  startDate: any;
  expiredDate: any;
  status: string;
  topupFee: string;
  oldstatus: string;
  name: string;
  listBankName: string;
  schoolEmail: string;
  schoolPhone: string;
  note: string;
  docpks: any;
  auto_renewal: any;
  renewal_in_days: any;
}

// interface getOnly {
//   name: string;
// }

interface items {
  listStatus: any;
  errorNamaSekolah: string;
  errorNomorPks: string;
  errorStartDate: string;
  errorEndDate: string;
  errorStatus: string;

  statusDetailHarcodeDoc: boolean;
  statusUpload: boolean;

  editable1: boolean;

  disablenote: boolean;
  disableother: boolean;

  showExtendPKS: boolean;
}

export default defineComponent({
  name: "Cabang",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading,
  },

  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();

    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0.5;

    // const editDetailValidator = Yup.object().shape({
    //   number: Yup.string().required("Nomor Dokumen Wajib Diisi"),
    //   startDate: Yup.string().required("Tanggal Mulai Wajib Diisi"),
    // });

    const editDetail = reactive<editDetail>({
      name: "",
      number: "",
      startDate: null,
      expiredDate: null,
      status: "",
      oldstatus: "",
      note: "",
      listBankName: "",
      topupFee: "",
      schoolEmail: "",
      schoolPhone: "",
      docpks: "",
      auto_renewal: false,
      renewal_in_days: 365,
    });

    // const getOnly = reactive<getOnly>({
    //   name: "",
    // });

    let items = reactive<items>({
      listStatus: [{}],
      errorNamaSekolah: "",
      errorNomorPks: "",
      errorStartDate: "",
      errorEndDate: "",
      errorStatus: "",

      statusDetailHarcodeDoc: false,
      statusUpload: false,

      editable1: true,
      disablenote: true,
      disableother: true,

      showExtendPKS: true,
    });

    function cekroleakses(role) {
      console.log(role);
      switch (role) {
        case "SUPER_ADMIN":
          items.disablenote = false;
          items.disableother = false;
          break;
        case "SALES_LEADER":
          items.disablenote = false;
          items.disableother = true;
          break;
        case "SALES_AREA":
          items.disablenote = false;
          items.disableother = true;
          break;
        case "CS_LEADER":
          items.disablenote = false;
          items.disableother = true;
          break;
        case "CS_MEMBER":
          items.disablenote = false;
          items.disableother = true;
          break;

        case "IMPLEMENTATOR_LEADER":
          items.disablenote = true;
          items.disableother = true;
          break;
        case "IMPLEMENTATOR_MEMBER":
          items.disablenote = true;
          items.disableother = true;
          break;
        case "ADMIN_LEGAL":
          items.disablenote = false;
          items.disableother = false;
          break;
      }
    }

    function dateTime(val) {
      if (val) {
        return moment(val).format("YYYY-MM-DD");
      } else {
        return "";
      }
    }

    function changeBahasa(val) {
      // console.log(val)
      switch (val) {
        case "Waiting":
          return "Menunggu";
          break;
        case "Process":
          return "Proses";
          break;
        case "Reject":
          return "Ditolak";
          break;
        case "Cancel":
          return "Batal";
          break;
        case "Approve":
          return "Disetujui";
          break;
      }
    }

    const getLegalStatus = () => {
      ApiService.getWithoutSlug("crmv2/main_legal/legal/legal/combo/status")
        .then(({ data }) => {
          items.listStatus = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const getIdDetailLegalitas = () => {
      ApiService.getWithoutSlug(
        "crmv2/main_legal/legal/legal/get_document_legal/" +
          route.params.idSekolah +
          "/" +
          route.params.idDokumen
      )
        .then((res) => {
          // if(res.data.documentLegality.status.hardCopy == null) {
          //   items.statusDetailHarcodeDoc = true;
          // }

          // console.log(res.data.documentLegality.expiredDate);
          // getOnly.name = res.data.name;

          editDetail.name = res.data.name;
          editDetail.listBankName = res.data.listBankName;
          editDetail.topupFee = res.data.topupFee;
          editDetail.schoolEmail = res.data.schoolEmail;
          editDetail.schoolPhone = res.data.schoolPhone;
          editDetail.number = res.data.documentLegality.number;
          // console.log(res.data.documentLegality.startDate)
          if (res.data.documentLegality.startDate) {
            // console.log('true',res.data.documentLegality.startDate)
            editDetail.startDate = dateTime(
              res.data.documentLegality.startDate
            );
          } else {
            // console.log('false',res.data.documentLegality.startDate)
            editDetail.startDate = res.data.documentLegality.startDate;
          }
          if (res.data.documentLegality.expiredDate) {
            editDetail.expiredDate = dateTime(
              res.data.documentLegality.expiredDate
            );
          } else {
            editDetail.expiredDate = res.data.documentLegality.expiredDate;
          }

          editDetail.note = res.data.documentLegality.note;
          editDetail.status = res.data.documentLegality.status;
          editDetail.oldstatus = res.data.documentLegality.status;

          editDetail.auto_renewal = res.data.documentLegality.auto_renewal;
          editDetail.renewal_in_days =
            res.data.documentLegality.renewal_in_days;
        })
        .catch((e) => {
          toast.error(e.response.data.detail);
        });
    };

    const saveChanges1 = () => {
      if (editDetail.name === "" || editDetail.status === "") {
        if (editDetail.name === "") {
          items.errorNamaSekolah = "Nama Sekolah Wajib Diisi";
        }
        if (editDetail.status === "") {
          items.errorStatus = "Status Wajib Diisi";
        }
        toast.error("Form belum lengkap");
        // items.errorfile = "File Dokumen Wajib Diisi";
      } else {
        // console.log("save2");
        items.errorNamaSekolah = "";
        items.errorNomorPks = "";
        items.errorStartDate = "";
        items.errorEndDate = "";
        items.errorStatus = "";
        isLoading.value = true;

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();

        var todaynow = yyyy + "-" + mm + "-" + dd;
        // 2022-07-15

        submitButton.value?.setAttribute("data-kt-indicator", "on");

        if (editDetail.startDate > editDetail.expiredDate) {
          console.log("errordate");
          toast.error("Tanggal Mulai tidak boleh lebih dari Tanggal Selesai");
          isLoading.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
        } else {
          if (route.params.idSekolah) {
            console.log("idschool", route.params.idSekolah);
            setTimeout(() => {
              console.log("oldstatus", editDetail.oldstatus);
              console.log("status", editDetail.status);

              setTimeout(() => {
                ApiService.putWithData(
                  "crmv2/main_legal/legal/legal/" +
                    route.params.idSekolah +
                    "/" +
                    route.params.idDokumen,
                  editDetail
                )
                  .then((res) => {
                    if (
                      editDetail.status == "Approve" &&
                      editDetail.oldstatus != "Approve"
                    ) {
                      ApiService.PostMethodWithoutData(
                        "crmv2/main_legal/legal/legal/create_hard_copy?schoolId=" +
                          route.params.idSekolah +
                          "&documentId=" +
                          route.params.idDokumen +
                          "&hardCopyStatus=waiting" +
                          "&tanggal=" +
                          todaynow +
                          "&editable=true"
                      )
                        .then((res) => {
                          console.log("sukses create hardcopy");
                        })
                        .catch((e) => {
                          toast.error(e.response.data.detail);
                          isLoading.value = false;
                        });
                    }

                    if (items.statusUpload == true) {
                      // console.log('Status Upload True')
                      let formData = new FormData();
                      formData.append("documentFile", editDetail.docpks);
                      setTimeout(() => {
                        ApiService.postWithData(
                          "crmv2/main_legal/legal/legal/" +
                            route.params.idSekolah +
                            "/" +
                            route.params.idDokumen,
                          formData
                        )
                          .then((res) => {
                            toast.success("Berhasil Update dan Upload Dokumen");
                            isLoading.value = false;
                            router.push({
                              path: "/superadmin/legal/legalitas",
                            });
                          })
                          .catch((e) => {
                            isLoading.value = false;
                            toast.error(e.response.data.detail);
                          });
                      }, 1000);
                    } else {
                      // console.log('status',items.statusUpload)
                      toast.success("Berhasil Update Detail Legalitas");
                      isLoading.value = false;
                      router.push({ path: "/superadmin/legal/legalitas" });
                    }
                    // if(editDetail.status != 'Approve'){
                    //   isLoading.value = false;
                    //   router.push({path: "/superadmin/legal/legalitas",});
                    // }else{
                    //   ApiService.PostMethodWithoutData(
                    //     "crmv2/main_legal/legal/legal/create_hard_copy?schoolId="+route.params.idSekolah +
                    //     "&documentId=" +route.params.idDokumen +
                    //     "&hardCopyStatus=waiting" +
                    //     "&tanggal=" +todaynow
                    //   )
                    //   .then((res) => {
                    //       isLoading.value = false;
                    //       router.push({path: "/superadmin/legal/legalitas",});
                    //   })
                    //   .catch((e) => {
                    //       toast.error(e.response.data.detail);
                    //       isLoading.value = false;
                    //   });
                    // }
                  })
                  .catch((e) => {
                    toast.error(e.response.data.detail);
                    isLoading.value = false;
                  });
              }, 1000);
              submitButton.value?.removeAttribute("data-kt-indicator");
            }, 2000);
          } else {
            isLoading.value = false;
            router.push({ path: "/superadmin/legal/legalitas" });
          }
        }
      }
    };

    function handleFileUpload(event) {
      console.log("event", event);
      if (event.target.files[0]) {
        items.statusUpload = true;
      } else {
        items.statusUpload = false;
      }
      editDetail.docpks = event.target.files[0];
      // console.log('doc',editDetail.docpks);
      // console.log('statusUpload',items.statusUpload);
    }

    // beforeMount() {
    //   store.dispatch(Actions.VERIFY_AUTH);
    // },

    onMounted(() => {
      store.dispatch(Actions.VERIFY_AUTH);
      getIdDetailLegalitas();
      getLegalStatus();

      var roleuser = JSON.parse(localStorage.getItem("user_account")!).role;
      cekroleakses(roleuser);

      var typelegal = localStorage.getItem("typelegal");
      cekroleakses(typelegal);
      if (typelegal == "PKS") {
        items.showExtendPKS = true;
      } else {
        items.showExtendPKS = false;
      }
    });

    return {
      submitButton,
      saveChanges1,
      editDetail,
      getIdDetailLegalitas,
      getLegalStatus,
      items,
      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,

      cekroleakses,
      changeBahasa,

      handleFileUpload,
    };
  },
});
