
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import moment from "moment";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import Loading from "vue-loading-overlay";

interface editDetail {
  number: string;
  startDate: string;
  expiredDate: string;
  status: string;
  name: string;
  note: string;
  docpks: any;
}

// interface getOnly {
//   name: string;
// }

interface items {
  listStatus: any;
  errorNamaSekolah: string;
  errorNomorPks: string;
  errorStartDate: string;
  errorEndDate: string;
  errorStatus: string;

  tableData: any;
  lengtable: any;
}

export default defineComponent({
  name: "Cabang",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading,
  },

  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();

    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0.5;

    // const editDetailValidator = Yup.object().shape({
    //   number: Yup.string().required("Nomor Dokumen Wajib Diisi"),
    //   startDate: Yup.string().required("Tanggal Mulai Wajib Diisi"),
    // });

    const editDetail = reactive<editDetail>({
      name: "",
      number: "",
      startDate: "",
      expiredDate: "",
      status: "",
      note: "",
      docpks: "",
    });

    // const getOnly = reactive<getOnly>({
    //   name: "",
    // });

    let items = reactive<items>({
      listStatus: [{}],
      errorNamaSekolah: "",
      errorNomorPks: "",
      errorStartDate: "",
      errorEndDate: "",
      errorStatus: "",

      tableData:[],
      lengtable:0,
    });

    function dateTime(val) {
      if (val) {
        return moment(val).format("YYYY-MM-DD");
      } else {
        return "";
      }
    }

    const getLegalStatus = () => {
      ApiService.getWithoutSlug(
        "crmv2/main_legal/legal/legal/combo/status"
      )
        .then(({ data }) => {
          items.listStatus = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const getDetailLegalitas = () => {
      ApiService.getWithoutSlug(        
        "crmv2/main_legal/legal/legal/"+
          route.params.idSekolah
      )
        .then((res) => {
          console.log(res.data.documentLegality);
          items.tableData = res.data.documentLegality;

          // console.log(items.tableData.length);
          items.lengtable = items.tableData.length;
          // console.log(items.lengtable);
        })
        .catch((e) => {
          toast.error(e.response.data.detail);
        });
    };


    onMounted(() => {
      store.dispatch(Actions.VERIFY_AUTH);
      getDetailLegalitas();
      getLegalStatus();
    });

    return {
      submitButton,
      editDetail,
      getDetailLegalitas,
      getLegalStatus,
      items,
      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,
      dateTime,
    };
  },
});
