import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "card mb-5 xl-3" }
const _hoisted_2 = { class: "card-body border-top py-5 px-9" }
const _hoisted_3 = { class: "d-flex flex-stack" }
const _hoisted_4 = { class: "d-flex" }
const _hoisted_5 = { class: "nav nav-tabs nav-line-tabs mb-5 fs-5" }
const _hoisted_6 = { class: "nav-item" }
const _hoisted_7 = {
  key: 0,
  class: "nav-item"
}
const _hoisted_8 = { class: "nav-item" }
const _hoisted_9 = { class: "card mb-1 mb-xl-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditLegalitasDokumenPKS = _resolveComponent("EditLegalitasDokumenPKS")!
  const _component_EditLegalitasDokumenHardcopy = _resolveComponent("EditLegalitasDokumenHardcopy")!
  const _component_EditLegalitasRiwayatDokumen = _resolveComponent("EditLegalitasRiwayatDokumen")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("ul", _hoisted_5, [
              _createElementVNode("li", _hoisted_6, [
                _createElementVNode("a", {
                  class: "nav-link active fs-5 text-dark text-hover-primary fw-bolder text-active-primary me-6",
                  "data-bs-toggle": "tab",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.DokumenPKS())),
                  style: {"cursor":"pointer"}
                }, "Dokumen PKS")
              ]),
              (_ctx.items.showEditHardcopy === true)
                ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
                    _createElementVNode("a", {
                      class: "nav-link fs-5 text-dark text-hover-primary fw-bolder text-active-primary me-6",
                      "data-bs-toggle": "tab",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.DokumenHardcopy())),
                      style: {"cursor":"pointer"}
                    }, "Dokumen Hardcopy")
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("li", _hoisted_8, [
                _createElementVNode("a", {
                  class: "nav-link fs-5 text-dark text-hover-primary fw-bolder text-active-primary me-6",
                  "data-bs-toggle": "tab",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.RiwayatDokumen())),
                  style: {"cursor":"pointer"}
                }, "Riwayat Dokumen")
              ])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      (_ctx.items.editPKS === true)
        ? (_openBlock(), _createBlock(_component_EditLegalitasDokumenPKS, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.items.editHardcopy === true)
        ? (_openBlock(), _createBlock(_component_EditLegalitasDokumenHardcopy, { key: 1 }))
        : _createCommentVNode("", true),
      (_ctx.items.riwayat === true)
        ? (_openBlock(), _createBlock(_component_EditLegalitasRiwayatDokumen, { key: 2 }))
        : _createCommentVNode("", true)
    ])
  ], 64))
}