
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import moment from "moment";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { hideModal } from "@/core/helpers/dom";
import Loading from "vue-loading-overlay";

interface editDetail {
  number: string;
  startDate: string;
  expiredDate: string;
  name: string;
  note: string;
  docpks: any;

  hardCopyId: string;
  status: string;
  date: string;
}

// interface getOnly {
//   name: string;
// }

interface items {
  listStatus0: any;
  listStatus: any;
  errorNamaSekolah: string;
  errorNomorPks: string;
  errorStartDate: string;
  errorEndDate: string;
  errorStatus: string;

  submit: boolean;

  valueaddeditstatus: string;
  nulldetailhardcopy: boolean;
  statusSimpan: boolean;

  tableData: any;
  lengtable: any;
  firstCreateTime: string;

  disablenote: boolean;
  showaction: boolean;
}

interface paging {
  page: any;
  totalPages: any;
  size: any;
  totalElements: any;
  perPageOptions: any;
  from: any;
  to: any;
}

export default defineComponent({
  name: "Cabang",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading,
  },

  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const uploadModalRef = ref<null | HTMLElement>(null);
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();

    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0.5;

    // const editDetailValidator = Yup.object().shape({
    //   number: Yup.string().required("Nomor Dokumen Wajib Diisi"),
    //   startDate: Yup.string().required("Tanggal Mulai Wajib Diisi"),
    // });

    const editDetail = reactive<editDetail>({
      name: "",
      number: "",
      startDate: "",
      expiredDate: "",
      note: "",
      docpks: "",

      hardCopyId: "",
      status: "",
      date: "",
    });

    // const getOnly = reactive<getOnly>({
    //   name: "",
    // });

    let items = reactive<items>({
      listStatus0: [],
      listStatus: [{}],
      errorNamaSekolah: "",
      errorNomorPks: "",
      errorStartDate: "",
      errorEndDate: "",
      errorStatus: "",

      submit: false,

      valueaddeditstatus: "add",
      nulldetailhardcopy: false,
      statusSimpan: false,

      tableData: [],
      lengtable: 0,
      firstCreateTime: "-",

      disablenote: false,
      showaction: true,
    });

    const paging = reactive<paging>({
      page: 0,
      totalPages: 0,
      size: 5,
      totalElements: 0,
      perPageOptions: [5, 10, 25, 50, 100],
      from: 0,
      to: 0,
    });

    function cekroleakses(role) {
      console.log(role);
      switch (role) {
        case "SUPER_ADMIN":
          items.disablenote = false;
          items.showaction = true;
          break;
        case "SALES_LEADER":
          items.disablenote = true;
          items.showaction = false;
          break;
        case "CS_LEADER":
          items.disablenote = true;
          items.showaction = false;
          break;
        case "CS_MEMBER":
          items.disablenote = true;
          items.showaction = false;
          break;
        case "SALES_AREA":
          items.disablenote = true;
          items.showaction = false;
          break;
        case "IMPLEMENTATOR_LEADER":
          items.disablenote = true;
          items.showaction = false;
          break;
        case "IMPLEMENTATOR_MEMBER":
          items.disablenote = true;
          items.showaction = false;
          break;
        case "ADMIN_LEGAL":
          items.disablenote = false;
          items.showaction = true;
          break;
      }
    }

    function getStatus(val) {
      // console.log(val)
      switch (val) {
        case "waiting":
          return "Menunggu dokumen hardcopy";
          break;
        case "received":
          return "Menerima dokumen hardcopy";
          break;
        case "rejected":
          return "Dokumen ditolak";
          break;
        case "resend":
          return "Pengiriman ulang kesekolah";
          break;
        case "approved":
          return "Dokumen disetujui";
          break;
        case "finished":
          return "Dokumen PKS selesai";
          break;
      }
    }

    function dateTime(val) {
      // console.log(val)
      if (val) {
        return moment(val).format("YYYY-MM-DD");
      } else {
        return "";
      }
    }

    const getLegalStatus = () => {
      items.listStatus0 = [
        { text: "Menunggu dokumen hardcopy", value: "waiting" },
      ];
      items.listStatus = [
        { text: "Menunggu dokumen hardcopy", value: "waiting" },
        { text: "Menerima dokumen hardcopy", value: "received" },
        { text: "Dokumen ditolak", value: "rejected" },
        { text: "Pengiriman ulang kesekolah", value: "resend" },
        { text: "Dokumen disetujui", value: "approved" },
        { text: "Dokumen PKS selesai", value: "finished" },
      ];
    };

    const getIdDetailLegalitas = () => {
      console.log(paging.page);
      console.log(paging.size);
      var pagenow = paging.page;
      var sizenow = paging.size;
      ApiService.getWithoutSlug(
        "crmv2/main_legal/legal/legal/get_hard_copy/" +
          route.params.idSekolah +
          "/" +
          route.params.idDokumen +
          "?" +
          "page=" +
          pagenow +
          "&size=" +
          sizenow +
          "&dir=1"
      )
        .then((res) => {
          console.log(res.data.content);
          editDetail.note = res.data.note;

          items.tableData = res.data.content;
          items.lengtable = items.tableData.length;

          paging.page = res.data.page;
          paging.size = res.data.size;
          paging.totalElements = res.data.totalElements;
          paging.totalPages = res.data.totalPages;

          paging.from = paging.size * paging.page;
          let highBound = paging.from + paging.size;
          if (paging.totalElements < highBound) {
            highBound = paging.totalElements;
          }
          paging.to = highBound;

          console.log(items.firstCreateTime);
          if (items.firstCreateTime == "-") {
            items.firstCreateTime = res.data.content[0].createTime;
          }
          console.log(items.firstCreateTime);
        })
        .catch((e) => {
          toast.error(e.response.data.detail);
        });
    };

    function saveChangesCatatan() {
      console.log("test1");
      isLoading.value = true;
      ApiService.PutMethodWithoutData(
        "crmv2/main_legal/legal/legal/update_note_hard_copy?schoolId=" +
          route.params.idSekolah +
          "&documentId=" +
          route.params.idDokumen +
          "&note=" +
          editDetail.note
      )
        .then((res) => {
          toast.success("Berhasil Update Catatan");
          isLoading.value = false;
          getIdDetailLegalitas();
        })
        .catch((e) => {
          toast.error(e.response.data.detail);
          isLoading.value = false;
        });
    }

    const saveChanges1 = () => {
      // Activate loading indicator
    };

    function showAddModal() {
      editDetail.hardCopyId = "";
      editDetail.status = "";
      editDetail.date = "";
      items.valueaddeditstatus = "add";
    }
    function showEditModal(index, id, status, tanggal) {
      editDetail.hardCopyId = id;
      editDetail.status = status;
      editDetail.date = dateTime(tanggal);
      items.valueaddeditstatus = "edit";
    }

    function saveModal() {
      // console.log(editDetail.date)
      if (editDetail.status == "" || editDetail.date == "") {
        if (editDetail.status == "" && editDetail.date == "") {
          toast.error("Gagal simpan, status atau tanggal belum di isi");
        } else {
          if (editDetail.status == "") {
            toast.error("Gagal simpan, status belum di isi");
          }
          if (editDetail.date == "") {
            toast.error("Gagal simpan, tanggal belum di isi");
          }
        }
      } else {
        if (items.valueaddeditstatus == "add") {
          ApiService.PostMethodWithoutData(
            "crmv2/main_legal/legal/legal/create_hard_copy?schoolId=" +
              route.params.idSekolah +
              "&documentId=" +
              route.params.idDokumen +
              "&hardCopyStatus=" +
              editDetail.status +
              "&tanggal=" +
              editDetail.date +
              "&editable=false"
          )
            .then((res) => {
              toast.success("Berhasil Tambah Detail Dokumen");
              isLoading.value = false;
              hideModal(uploadModalRef.value);
              getIdDetailLegalitas();
            })
            .catch((e) => {
              toast.error(e.response.data.detail);
              isLoading.value = false;
            });
        } else {
          ApiService.PutMethodWithoutData(
            "crmv2/main_legal/legal/legal/update_hard_copy?schoolId=" +
              route.params.idSekolah +
              "&documentId=" +
              route.params.idDokumen +
              "&hardCopyId=" +
              editDetail.hardCopyId +
              "&hardCopyStatus=" +
              editDetail.status +
              "&tanggal=" +
              editDetail.date
          )
            .then((res) => {
              toast.success("Berhasil Update Detail Dokumen");
              isLoading.value = false;
              hideModal(uploadModalRef.value);
              getIdDetailLegalitas();
            })
            .catch((e) => {
              toast.error(e.response.data.detail);
              isLoading.value = false;
            });
        }
      }
    }

    function range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    }

    function changePage(item) {
      const page = item - 1;
      paging.page = page;
      getIdDetailLegalitas();
    }
    function nextPage() {
      const page = paging.page + 1;
      paging.page = page;
      getIdDetailLegalitas();
    }
    function prevPage() {
      const page = paging.page - 1;
      paging.page = page;
      getIdDetailLegalitas();
    }
    function changePageSize(e) {
      const page = paging.page;
      paging.page = page;
      getIdDetailLegalitas();
    }

    onMounted(() => {
      store.dispatch(Actions.VERIFY_AUTH);
      getIdDetailLegalitas();
      getLegalStatus();

      var roleuser = JSON.parse(localStorage.getItem("user_account")!).role;
      cekroleakses(roleuser);
    });

    return {
      submitButton,
      saveChanges1,
      editDetail,
      getIdDetailLegalitas,
      getLegalStatus,
      items,
      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,
      paging,
      uploadModalRef,

      saveChangesCatatan,
      dateTime,
      getStatus,

      showAddModal,
      showEditModal,
      saveModal,

      range,
      changePage,
      nextPage,
      prevPage,
      changePageSize,

      cekroleakses,
    };
  },
});
